import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoggerService } from '../../services/logger.service';
import { AuthService } from '../../services/auth.service';
import { DialogService, SnackType } from '../../services/dialog.service';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(logger, auth, dialog, router) {
        this.logger = logger;
        this.auth = auth;
        this.dialog = dialog;
        this.router = router;
        this.credentials = {
            email: null,
            password: null
        };
    }
    LoginComponent.prototype.ngOnInit = function () { };
    LoginComponent.prototype.login = function () {
        var _this = this;
        this.auth.login(this.credentials.email, this.credentials.password)
            .subscribe(function (response) {
            if (_this.auth.redirectUrl) {
                _this.router.navigate([_this.auth.redirectUrl]);
            }
            else {
                _this.router.navigate(['/admin']);
            }
        }, function (error) {
            _this.logger.error(error);
            _this.dialog.snack('Usuário ou senha incorretos', SnackType.Error);
        });
    };
    return LoginComponent;
}());
export { LoginComponent };
