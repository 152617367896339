import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DialogService, SnackType } from 'src/app/services/dialog.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
import * as i2 from "../services/dialog.service";
import * as i3 from "@angular/router";
// enum de roles possíveis
export var UserRole;
(function (UserRole) {
    UserRole["ServerReset"] = "server_reset";
    UserRole["DemoReset"] = "demo_reset";
})(UserRole || (UserRole = {}));
;
var RoleGuard = /** @class */ (function () {
    function RoleGuard(auth, dialog, router) {
        this.auth = auth;
        this.dialog = dialog;
        this.router = router;
    }
    RoleGuard.prototype.canActivate = function (next, state) {
        var roles = next.data.guard.roles;
        var user = this.auth.getUser();
        // todos os roles recebidos devem estar presentes em user.roles
        if (roles.every(function (role) {
            return user.roles.indexOf(role) !== -1;
        })) {
            return true;
        }
        else {
            this.dialog.snack('Este usuário não possui permissões necessárias para acessar esta página', SnackType.Error);
            this.router.navigate(['/']);
            return false;
        }
    };
    RoleGuard.ngInjectableDef = i0.defineInjectable({ factory: function RoleGuard_Factory() { return new RoleGuard(i0.inject(i1.AuthService), i0.inject(i2.DialogService), i0.inject(i3.Router)); }, token: RoleGuard, providedIn: "root" });
    return RoleGuard;
}());
export { RoleGuard };
