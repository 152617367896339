import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// guards:
import { RoleGuard, UserRole } from 'src/app/guards/role.guard';

// page components:
import { ServerResetComponent } from './server-reset/server-reset.component';
import { DemoResetComponent } from './demo-reset/demo-reset.component';
import { ServersInfoComponent } from './servers-info/servers-info.component';
import { HomeComponent } from './home/home.component';

// routes:
export const AdminRoutes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'servers-info',
    canActivate: [RoleGuard],
    data: {
      guard: {
        roles: [UserRole.ServerReset],
      }
    },
    component: ServersInfoComponent
  },
  {
    path: 'server-reset',
    canActivate: [RoleGuard],
    data: {
      guard: {
        roles: [UserRole.ServerReset],
      }
    },
    component: ServerResetComponent
  },
  {
    path: 'demo-reset',
    canActivate: [RoleGuard],
    data: {
      guard: {
        roles: [UserRole.DemoReset],
      }
    },
    component: DemoResetComponent
  },
];
