<mat-card fxLayout="column" fxLayoutAlign="center space-between">
  <form
    #form="ngForm"
    fxLayout="column"
    fxLayoutAlign="center space-between"
  >
    <button mat-raised-button
      type="submit"
      color="warn"
      [disabled]="!form.valid"
      (click)="confirm()"
    >
      RESETAR O BANCO DE DEMO
    </button>
  </form>
</mat-card>

<mat-card>
  <app-table
    [columns]="columns"
    [data]="history"
    [dateSearch]="['when']"
    [this]="this"
  ></app-table>
</mat-card>
