import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';
// relativos ao DialogComponent
import { OnInit } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
import * as i2 from "@angular/material/dialog";
export var SnackType;
(function (SnackType) {
    SnackType[SnackType["Success"] = 0] = "Success";
    SnackType[SnackType["Error"] = 1] = "Error";
    SnackType[SnackType["Warning"] = 2] = "Warning";
    SnackType[SnackType["Info"] = 3] = "Info";
})(SnackType || (SnackType = {}));
;
var DialogService = /** @class */ (function () {
    function DialogService(snackBar, matDialog) {
        this.snackBar = snackBar;
        this.matDialog = matDialog;
        this.bars = [];
        this.open = false;
    }
    DialogService.prototype.snack = function (message, snackType) {
        var _snackType = snackType !== undefined ? snackType : SnackType.Success;
        this.bars.push({
            message: message,
            snackType: _snackType
        });
        if (!this.open) {
            this.openNext();
        }
    };
    DialogService.prototype.openNext = function () {
        var _this = this;
        this.open = true;
        var snack = this.bars.shift();
        this.snackBar.openFromComponent(SnackbarComponent, {
            duration: 2000,
            horizontalPosition: 'end',
            verticalPosition: 'top',
            data: { message: snack.message, snackType: snack.snackType }
        }).afterDismissed().subscribe(function () {
            if (_this.bars.length > 0) {
                _this.openNext();
            }
            else {
                _this.open = false;
            }
        });
    };
    /*************************************
     * recebe:
     *
     *   - title         : string
     *   - content       : opcional
     *      + body       : string[], opcional
     *      + this       : referencia pro this do component, opcional
     *      + forms      : opcional
     *        * text     : string, placeholder do form
     *        * type     : string, (text|textarea|date|daterange)
     *        * required : boolean, opcional
     *        * disabled : boolean, opcional
     *      + data       : object, usado para os valores do forms
     *   - buttons       : array, opcional
     *      + text       : string, texto mostrado no botão
     *      + callback   : function|any, se uma função for passada, ela é chamada
     *                      e  seu  valor de retorno é retornado; se um valor for
     *                      passado  este valor é passado de volta; caso a função
     *                      emita um erro, o dialog não é fechado
     *      + close      : boolean, se clicar no botão deve fechar o dialog
     *      + submit     : boolean, se clicar no botão deve ser permitido somente
     *                      quando o form estiver válido (forms.required)
     *
     ****/
    DialogService.prototype.modal = function (title, content, buttons) {
        // valores default
        var body = [];
        var forms = [];
        var data = {};
        var that = null;
        // recebe valores
        if (content) {
            if (content.body)
                body = content.body;
            if (content.forms)
                forms = content.forms;
            if (content.this)
                that = content.this;
            if (content.data)
                data = content.data;
        }
        buttons = buttons || [{ text: 'Ok', callback: true, close: true }];
        var dialogRef = this.matDialog.open(DialogComponent, {
            width: '450px',
            data: {
                title: title,
                body: body,
                forms: forms,
                buttons: buttons,
                data: data,
                that: that
            }
        });
        return dialogRef.afterClosed();
    };
    DialogService.ngInjectableDef = i0.defineInjectable({ factory: function DialogService_Factory() { return new DialogService(i0.inject(i1.MatSnackBar), i0.inject(i2.MatDialog)); }, token: DialogService, providedIn: "root" });
    return DialogService;
}());
export { DialogService };
// modal generico
var DialogComponent = /** @class */ (function () {
    function DialogComponent(data, dialogRef) {
        this.data = data;
        this.dialogRef = dialogRef;
    }
    DialogComponent.prototype.ngOnInit = function () {
        // pega valores passados
        this.title = this.data.title;
        this.body = this.data.body;
        this.forms = this.data.forms;
        this.buttons = this.data.buttons;
        this.formData = Object.assign({}, this.data.data);
        this.that = this.data.that;
    };
    DialogComponent.prototype.onClick = function (button) {
        if (button.close) {
            if (typeof button.callback === 'function') {
                // se o callback for uma função, retorna a chamada da função
                // se houver erro na callback, nao fecha o dialog
                var returnValue = void 0;
                try {
                    returnValue = button.callback(this.that, this.formData);
                }
                catch (ex) {
                    throw ex;
                    return;
                }
                this.dialogRef.close(returnValue);
            }
            else if ('callback' in button) {
                // se o callback for um valor, retorna um Observable do valor
                this.dialogRef.close(button.callback);
            }
            else {
                // caso contrário, fecha o diálogo sem valor
                this.dialogRef.close();
            }
        }
        else if (typeof button.callback == 'function') {
            var returnValue = void 0;
            try {
                returnValue = button.callback(this.that, this.formData);
            }
            catch (ex) {
                throw ex;
                return;
            }
        }
    };
    return DialogComponent;
}());
export { DialogComponent };
// snackbar
var SnackbarComponent = /** @class */ (function () {
    function SnackbarComponent(data, overlayContainer) {
        this.data = data;
        this.overlayContainer = overlayContainer;
    }
    SnackbarComponent.prototype.ngOnInit = function () {
        this.setSnackbarClass(this.getSnackClass());
    };
    SnackbarComponent.prototype.setSnackbarClass = function (snackClass) {
        var overlay = this.overlayContainer.getContainerElement();
        var sn = overlay.querySelectorAll('snack-bar-container')[0];
        sn.classList.add(snackClass);
    };
    Object.defineProperty(SnackbarComponent.prototype, "getIcon", {
        get: function () {
            switch (this.data.snackType) {
                case SnackType.Success:
                    return 'check-outline';
                case SnackType.Error:
                    return 'alert-decagram';
                case SnackType.Warning:
                    return 'alert';
                case SnackType.Info:
                    return 'information';
            }
        },
        enumerable: true,
        configurable: true
    });
    SnackbarComponent.prototype.getSnackClass = function () {
        switch (this.data.snackType) {
            case SnackType.Success:
                return 'snackbar-green';
            case SnackType.Error:
                return 'snackbar-red';
            case SnackType.Warning:
                return 'snackbar-yellow';
            case SnackType.Info:
                return 'snackbar-blue';
        }
    };
    return SnackbarComponent;
}());
export { SnackbarComponent };
