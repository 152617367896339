import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
var LoggerService = /** @class */ (function () {
    function LoggerService() {
    }
    LoggerService.prototype.stack = function () {
        var s = Error().stack.split('\n');
        var callee = s[2];
        var str = callee.replace(/[@].*/, '')
            .replace('./src/app/', '');
        return '[' + str + ']\n';
    };
    LoggerService.prototype.info = function (msg) {
        if (environment.debug) {
            console.log("%c" + this.stack(), "color: grey;", msg);
        }
        else if (environment.production === false) {
            console.log(msg);
        }
    };
    LoggerService.prototype.error = function (msg) {
        if (environment.debug) {
            console.error("%c" + this.stack(), "color: grey;", msg);
        }
        else if (environment.production === false) {
            console.error(msg);
        }
    };
    LoggerService.prototype.warn = function (msg) {
        if (environment.debug) {
            console.warn("%c" + this.stack(), "color: grey;", msg);
        }
        else if (environment.production === false) {
            console.warn(msg);
        }
    };
    LoggerService.ngInjectableDef = i0.defineInjectable({ factory: function LoggerService_Factory() { return new LoggerService(); }, token: LoggerService, providedIn: "root" });
    return LoggerService;
}());
export { LoggerService };
