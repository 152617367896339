import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';

// relativos ao DialogComponent
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

// relativos ao Snackbar
import { MAT_SNACK_BAR_DATA } from '@angular/material';
import { OverlayContainer } from '@angular/cdk/overlay';
export enum SnackType {
  Success,
  Error,
  Warning,
  Info,
};

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  bars = [];
  open = false;

  constructor(private snackBar: MatSnackBar, private matDialog: MatDialog) {}

  public snack(message: string, snackType?: SnackType) {
    const _snackType: SnackType =
      snackType !== undefined ? snackType : SnackType.Success;

    this.bars.push({
      message: message,
      snackType: _snackType
    });

    if (!this.open) {
      this.openNext();
    }
  }

  private openNext() {
    this.open = true;
    let snack = this.bars.shift();
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 2000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      data: { message: snack.message, snackType: snack.snackType }
    }).afterDismissed().subscribe(() => {
      if (this.bars.length > 0) {
        this.openNext();
      }
      else {
        this.open = false
      }
    });
  }

  /*************************************
   * recebe:
   *
   *   - title         : string
   *   - content       : opcional
   *      + body       : string[], opcional
   *      + this       : referencia pro this do component, opcional
   *      + forms      : opcional
   *        * text     : string, placeholder do form
   *        * type     : string, (text|textarea|date|daterange)
   *        * required : boolean, opcional
   *        * disabled : boolean, opcional
   *      + data       : object, usado para os valores do forms
   *   - buttons       : array, opcional
   *      + text       : string, texto mostrado no botão
   *      + callback   : function|any, se uma função for passada, ela é chamada
   *                      e  seu  valor de retorno é retornado; se um valor for
   *                      passado  este valor é passado de volta; caso a função
   *                      emita um erro, o dialog não é fechado
   *      + close      : boolean, se clicar no botão deve fechar o dialog
   *      + submit     : boolean, se clicar no botão deve ser permitido somente
   *                      quando o form estiver válido (forms.required)
   *
   ****/
  public modal(title: string,
                content?: any,
                buttons?: { text: string, callback?: any, close?: boolean }[],
  ){
    // valores default
    let body = [];
    let forms = [];
    let data = {};
    let that = null;
    // recebe valores
    if( content ){
      if( content.body ) body = content.body;
      if( content.forms ) forms = content.forms;
      if( content.this ) that = content.this;
      if( content.data ) data = content.data;
    }
    buttons = buttons || [ { text: 'Ok',     callback: true,  close: true } ];

	  let dialogRef = this.matDialog.open(DialogComponent, {
      width: '450px',
      data: {
        title: title,
        body: body,
        forms: forms,
        buttons: buttons,
        data: data,
        that: that
      }
    });

    return dialogRef.afterClosed();
  }

}

// modal generico
@Component({
  selector: 'app-dialog-message-generic',
  template: `
<h1 mat-dialog-title>{{title}}</h1>

<div mat-dialog-content>
  <!-- body -->
  <ng-container *ngFor="let line of [].concat(body)">
    <p *ngFor="let subline of [].concat(line.split('\n'))">{{subline}}</p>
  </ng-container>
  <!-- forms -->
  <form #form="ngForm">
    <ng-container
      *ngFor="let form of forms; let i = index"
      [ngSwitch]="form.type"
    >
      <mat-form-field *ngSwitchCase="'text'" class="w-100">
        <input matInput
          [name]="'input-'+i"
          [placeholder]="form.text"
          [(ngModel)]="formData[form.variable]"
          [required]="form.required"
          [disabled]="form.disabled"
        >
      </mat-form-field>
      <mat-form-field *ngSwitchCase="'textarea'" class="w-100">
        <textarea matInput
          [name]="'input-'+i"
          [placeholder]="form.text"
          [(ngModel)]="formData[form.variable]"
          [required]="form.required"
          [disabled]="form.disabled"
        ></textarea>
      </mat-form-field>
      <mat-form-field *ngSwitchCase="'date'" class="w-100">
        <input matInput
          [name]="'input-'+i"
          [placeholder]="form.text"
          [(ngModel)]="formData[form.variable]"
          [required]="form.required"
          [disabled]="form.disabled"
          [owlDateTimeTrigger]="rangeDatepicker"
          [owlDateTime]="rangeDatepicker"
        >
        <owl-date-time #rangeDatepicker></owl-date-time>
      </mat-form-field>
      <mat-form-field *ngSwitchCase="'daterange'" class="w-100">
        <input matInput
          [name]="'input-'+i"
          [placeholder]="form.text"
          [(ngModel)]="formData[form.variable]"
          [required]="form.required"
          [disabled]="form.disabled"
          [selectMode]="'range'"
          [owlDateTimeTrigger]="rangeDatepicker"
          [owlDateTime]="rangeDatepicker"
        >
        <owl-date-time #rangeDatepicker></owl-date-time>
      </mat-form-field>
    </ng-container>
  </form>
</div>

<!-- buttons -->
<div mat-dialog-actions fxLayoutAlign="space-between" class="w-100">
  <button mat-button
    *ngFor="let button of buttons"
    [disabled]="button.submit && !form.valid"
    (click)="onClick(button)">{{button.text}}
  </button>
</div>
  `,
})
export class DialogComponent implements OnInit {
  forms: { nome:string, tipo:string, data:string }[];
  title: string;
  body: string[];
  buttons: { text: string, callback?: any, close?: boolean }[];
  formData: any;
  that: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
	      	    public dialogRef: MatDialogRef<DialogComponent>
  ) { }

  ngOnInit() {
    // pega valores passados
    this.title = this.data.title;
    this.body = this.data.body;
    this.forms = this.data.forms;
    this.buttons = this.data.buttons;
    this.formData = Object.assign({},this.data.data);
    this.that = this.data.that;
  }

  onClick(button: any): void {
    if( button.close ){
      if( typeof button.callback === 'function' ){
        // se o callback for uma função, retorna a chamada da função
        // se houver erro na callback, nao fecha o dialog
        let returnValue;
        try{
          returnValue = button.callback(this.that,this.formData);
        }catch(ex){
          throw ex;
          return;
        }
        this.dialogRef.close(returnValue);
      } else if ( 'callback' in button ){
        // se o callback for um valor, retorna um Observable do valor
        this.dialogRef.close(button.callback);
      } else {
        // caso contrário, fecha o diálogo sem valor
        this.dialogRef.close();
      }
    } else if (typeof button.callback == 'function'){
	    let returnValue;
	    try{
	      returnValue = button.callback(this.that,this.formData);
	    }catch(ex){
        throw ex;
	      return;
	    }
    }
  }

}

// snackbar
@Component({
  selector: 'app-snackbar',
  template: `
<div fxLayout="row" class="snack-container">
  <div>
    <mat-icon svgIcon="{{ getIcon }}"></mat-icon>
    <span class="snack-text">{{ data.message }}</span>
  </div>
</div>
  `,
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
              private overlayContainer: OverlayContainer) {
  }

  ngOnInit() {
    this.setSnackbarClass(this.getSnackClass());
  }

  setSnackbarClass(snackClass: string){
    let overlay = this.overlayContainer.getContainerElement();
    let sn = overlay.querySelectorAll('snack-bar-container')[0];
    sn.classList.add(snackClass);
  }

  get getIcon(){
    switch (this.data.snackType) {
      case SnackType.Success:
        return 'check-outline';
      case SnackType.Error:
        return 'alert-decagram';
      case SnackType.Warning:
        return 'alert';
      case SnackType.Info:
        return 'information';
    }
  }

  getSnackClass(){
    switch (this.data.snackType) {
      case SnackType.Success:
        return 'snackbar-green';
      case SnackType.Error:
        return 'snackbar-red';
      case SnackType.Warning:
        return 'snackbar-yellow';
      case SnackType.Info:
        return 'snackbar-blue';
    }
  }
}
