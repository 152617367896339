import { Injectable } from '@angular/core';

/*****************************
 * Mais informação:
 *
 *   https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage
 *   https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
 *
 */

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() { }

  public set(key: string, value: string) {
    sessionStorage.setItem(key,JSON.stringify(value));
  }

  public get(key: string) {
    return JSON.parse(sessionStorage.getItem(key));
  }
}
