import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LoggerService } from '../../services/logger.service';
import { AuthService } from '../../services/auth.service';
import { DialogService, SnackType } from '../../services/dialog.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private logger: LoggerService,
    private auth: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.auth.logout()
        .subscribe((response) => {
          this.router.navigate(['/']);
        });
  }

}
