<div mat-dialog-content>
  <p>Selecione quais colunas deseja visualizar</p>
  <p *ngFor="let column of data.columns">
    <mat-checkbox
      [checked]="column.show"
      (change)="column.show = !column.show"
      [name]="column.value">
      {{ column.name }}
    </mat-checkbox>
  </p>
</div>

<div mat-dialog-actions fxLayoutAlign="space-between">
  <button mat-button (click)="onYesClick()" cdkFocusInitial>Ok</button>
</div>
