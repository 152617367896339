<mat-card fxLayout="column" fxLayoutAlign="center space-between" fxLayoutGap="25px">
  <button mat-stroked-button
    *ngIf="user.roles.indexOf(UserRole.ServerReset) !== -1"
    type="submit"
    (click)="goToServersInfo()"
  >
    INFORMAÇÕES DOS SERVIDORES
  </button>

  <button mat-stroked-button
    *ngIf="user.roles.indexOf(UserRole.ServerReset) !== -1"
    type="submit"
    (click)="goToServerReset()"
  >
    REINICIAR UM SERVIDOR
  </button>

  <button mat-stroked-button
    *ngIf="user.roles.indexOf(UserRole.DemoReset) !== -1"
    type="submit"
    (click)="goToDemoReset()"
  >
    RESETAR O BANCO DE DEMO
  </button>
</mat-card>
