import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  constructor() { }

  stack(){
    const s = Error().stack.split('\n');
    const callee = s[2];
    const str = callee.replace(/[@].*/,'')
                      .replace('./src/app/','');
    return '['+str+']\n';
  }

  info(msg: any) {
    if( environment.debug ) {
      console.log("%c"+this.stack(),"color: grey;",msg);
    } else if ( environment.production === false ) {
      console.log(msg);
    }
  }

  error(msg: any) {
    if( environment.debug ){
      console.error("%c"+this.stack(),"color: grey;",msg);
    } else if ( environment.production === false ) {
      console.error(msg);
    }
  }

  warn(msg: any) {
    if( environment.debug ){
      console.warn("%c"+this.stack(),"color: grey;",msg);
    } else if ( environment.production === false ) {
      console.warn(msg);
    }
  }

}
