import { finalize, tap } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LoggerService } from '../services/logger.service';
/************************************
 *
 * Quando environment.production === true:
 *   não faz nada com a requisição
 *
 * Quando environment.production === false:
 *   chama LoggerService.log para todas as requisições http
 *
 */
var LoggerInterceptor = /** @class */ (function () {
    function LoggerInterceptor(logger) {
        this.logger = logger;
    }
    LoggerInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        var started = Date.now();
        var ok;
        if (environment.production) {
            return next.handle(req);
        }
        else {
            // extend server response observable with logging
            return next.handle(req)
                .pipe(tap(
            // Succeeds when there is a response; ignore other events
            function (event) { return ok = event instanceof HttpResponse ? 'succeeded' : ''; }, 
            // Operation failed; error is an HttpErrorResponse
            function (error) { return ok = 'failed'; }), 
            // Log when response observable either completes or errors
            finalize(function () {
                var elapsed = Date.now() - started;
                var msg = req.method + " \"" + req.urlWithParams + "\"\n             " + ok + " in " + elapsed + " ms.";
                _this.logger.info(msg);
            }));
        }
    };
    return LoggerInterceptor;
}());
export { LoggerInterceptor };
