import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// shared modules:
import { SharedModule } from '../../shared.module';
import { ComponentsModule } from '../../components/components.module';

// page components:
import { ServerResetComponent } from './server-reset/server-reset.component';
import { DemoResetComponent } from './demo-reset/demo-reset.component';
import { HomeComponent } from './home/home.component';
import { ServersInfoComponent } from './servers-info/servers-info.component';

@NgModule({
  declarations: [
    ServerResetComponent,
    DemoResetComponent,
    HomeComponent,
    ServersInfoComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ComponentsModule,
  ]
})
export class AdminModule { }
