import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';

/************************************
 *
 * Pega o bearer token de AuthService.getAuthorizationToken()
 *   e adiciona como header de 'Authorization'
 * Também adiciona header de 'Content-type' para aceitar json
 *
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the auth token from the service.
    const authToken = this.auth.getAuthorizationToken();

    // Clone the request and set the new header in one step.
    const authReq = req.clone({ setHeaders: {
      Authorization: 'Bearer '+authToken,
      'Content-type': 'application/json'
    } });

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}
