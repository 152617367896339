import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { LoggerService } from './logger.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private logger: LoggerService,
    private session: SessionService,
    private http: HttpClient
  ) { }

  public reset(serverName: string) {
    return this.http.post('/admin/reset/server', { server: serverName });
  }

  public resetDemoDatabase() {
    return this.http.post('/admin/reset/demo', {});
  }

}
