import { HttpClient } from '@angular/common/http';
import { LoggerService } from './logger.service';
import { SessionService } from './session.service';
import { ServerName } from './history.service';
import * as i0 from "@angular/core";
import * as i1 from "./logger.service";
import * as i2 from "./session.service";
import * as i3 from "@angular/common/http";
var ServerService = /** @class */ (function () {
    function ServerService(logger, session, http) {
        this.logger = logger;
        this.session = session;
        this.http = http;
        this.serversUrl = {};
        this.serversUrl[ServerName.BR] = 'https://a.br.aware.blue:1344/health';
        this.serversUrl[ServerName.PNP] = 'https://a.panalpina.awarelog.com:1344/health';
        this.serversUrl[ServerName.LATAM] = 'https://a.latam.aware.blue:1344/health';
        this.serversUrl[ServerName.TPX] = 'https://a.tpx.aware.blue:1341/health';
        this.serversUrl[ServerName.MX] = 'https://a.mx.aware.blue:1344/health';
        this.serversUrl[ServerName.MEXICO] = 'https://a.mexico.aware.blue:1344/health';
        this.serversUrl[ServerName.API] = 'https://api.br.aware.blue:1344/health';
        this.serversUrl[ServerName.SAAS] = 'https://a.saas.awarelog.com:1348/info';
        this.serversUrl[ServerName.SAAS_QA] = 'https://a.qa.saas.awarelog.com/info';
    }
    ServerService.prototype.uptime = function (server) {
        return this.http.get(this.serversUrl[server] + "/uptime", { responseType: 'text' });
    };
    ServerService.prototype.version = function (server) {
        return this.http.get(this.serversUrl[server] + "/version", { responseType: 'text' });
    };
    ServerService.prototype.ping = function (server) {
        return this.http.get(this.serversUrl[server] + "/ping");
    };
    ServerService.prototype.certificate = function (server) {
        return this.http.get("/admin/certificate/" + server);
    };
    ServerService.ngInjectableDef = i0.defineInjectable({ factory: function ServerService_Factory() { return new ServerService(i0.inject(i1.LoggerService), i0.inject(i2.SessionService), i0.inject(i3.HttpClient)); }, token: ServerService, providedIn: "root" });
    return ServerService;
}());
export { ServerService };
