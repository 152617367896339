/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./servers-info.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "../../../components/table/table.component.ngfactory";
import * as i5 from "../../../components/table/table.component";
import * as i6 from "../../../services/xlsx.service";
import * as i7 from "@angular/material/dialog";
import * as i8 from "@angular/router";
import * as i9 from "ngx-cookie-service";
import * as i10 from "./servers-info.component";
import * as i11 from "../../../services/history.service";
import * as i12 from "../../../services/servers.service";
import * as i13 from "../../../services/dialog.service";
var styles_ServersInfoComponent = [i0.styles];
var RenderType_ServersInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ServersInfoComponent, data: {} });
export { RenderType_ServersInfoComponent as RenderType_ServersInfoComponent };
export function View_ServersInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "mat-card", [["class", "mat-card"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "app-table", [], null, null, null, i4.View_TableComponent_0, i4.RenderType_TableComponent)), i1.ɵdid(3, 638976, null, 0, i5.TableComponent, [i6.XlsxService, i7.MatDialog, i8.Router, i9.CookieService], { parent: [0, "parent"], columns: [1, "columns"], data: [2, "data"], dateSearch: [3, "dateSearch"] }, null), i1.ɵpad(4, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co; var currVal_1 = _co.columns; var currVal_2 = _co.history; var currVal_3 = _ck(_v, 4, 0, "when"); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_ServersInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-servers-info", [], null, null, null, View_ServersInfoComponent_0, RenderType_ServersInfoComponent)), i1.ɵdid(1, 114688, null, 0, i10.ServersInfoComponent, [i11.HistoryService, i12.ServerService, i13.DialogService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ServersInfoComponentNgFactory = i1.ɵccf("app-servers-info", i10.ServersInfoComponent, View_ServersInfoComponent_Host_0, {}, {}, []);
export { ServersInfoComponentNgFactory as ServersInfoComponentNgFactory };
