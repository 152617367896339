import { common } from './common';

export const environment = {
  production: true,
  debug: false,
  api: {
    url: 'https://a.admin.aware.blue:1312'
  },
  common: common
};
