import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
/************************************
 *
 * Adiciona um errorHandler a toda requisição http
 *
 */
var ErrorInterceptor = /** @class */ (function () {
    function ErrorInterceptor() {
    }
    ErrorInterceptor.prototype.errorHandler = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + JSON.stringify(error.error)));
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    };
    ;
    ErrorInterceptor.prototype.intercept = function (req, next) {
        return next.handle(req)
            .pipe(catchError(this.errorHandler));
    };
    return ErrorInterceptor;
}());
export { ErrorInterceptor };
