import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LoggerService } from '../../services/logger.service';
import { AuthService } from '../../services/auth.service';
import { DialogService, SnackType } from '../../services/dialog.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public credentials = {
    email: <string> null,
    password: <string> null
  };

  constructor(
    private logger: LoggerService,
    private auth: AuthService,
    private dialog: DialogService,
    private router: Router
  ) { }

  ngOnInit() { }

  login() {
    this.auth.login(this.credentials.email,this.credentials.password)
        .subscribe((response) => {
          if( this.auth.redirectUrl ){
            this.router.navigate([this.auth.redirectUrl]);
          }else{
            this.router.navigate(['/admin']);
          }
        },(error) => {
          this.logger.error(error);
          this.dialog.snack('Usuário ou senha incorretos', SnackType.Error);
        });
  }
}
