import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

/************************************
 *
 * Intercepta requisições para urls iniciadas com '/'
 *   e prefixa elas com a url da API do backend
 *
 */
@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if( req.url.indexOf('/') === 0 ){ // url começa com '/'
      // clone request and replace url
      const apiReq = req.clone({
        url: environment.api.url + req.url
      });
      return next.handle(apiReq);
    } else {
      // não faz nada
      return next.handle(req);
    }
  }
}
