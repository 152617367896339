<mat-card fxLayout="column" fxLayoutAlign="center space-between">
  <form
    #form="ngForm"
    fxLayout="column"
    fxLayoutAlign="center space-between"
  >
    <mat-form-field>
      <mat-label>Selecione o servidor:</mat-label>
      <mat-select [(ngModel)]="selected" name="select" required>
        <mat-option>Nenhum</mat-option>
        <mat-option
          *ngFor="let server of serverList"
          [value]="server"
        >
          {{ server }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-raised-button
      type="submit"
      color="warn"
      [disabled]="!form.valid"
      (click)="confirm()"
    >
      RESETAR UM SERVIDOR
    </button>
  </form>
</mat-card>

<mat-card>
  <app-table
    [columns]="columns"
    [data]="history"
    [dateSearch]="['when']"
    [this]="this"
  ></app-table>
</mat-card>
