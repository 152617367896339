import { Component, OnInit } from '@angular/core';

import { HistoryService, ActionType, ServerName } from 'src/app/services/history.service';
import { AdminService } from 'src/app/services/admin.service';
import { DialogService, SnackType } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-server-reset',
  templateUrl: './server-reset.component.html',
  styleUrls: ['./server-reset.component.scss']
})
export class ServerResetComponent implements OnInit {
  columns = [
    {name:'Resultado',value:'icon',icon:'icon',color:this.styleIcon},
    {name:'Servidor',value:'server'},
    {name:'O que',value:'what'},
    {name:'Quando',value:'when'},
    {name:'Quem',value:'user'},
    {name:'Observações',value:'obs'},
  ];
  history: any[] = [];
  icons = {
    'undefined': 'help',
    'red':       'error',
    'yellow':    'warning',
    'green':     'check_circle'
  };

  serverList = [
    ServerName.BR,
    ServerName.PNP,
    ServerName.LATAM,
    ServerName.TPX,
    ServerName.MEXICO,
    ServerName.API
  ];
  selected;

  constructor(
    private historyS: HistoryService,
    private adminS: AdminService,
    private dialog: DialogService,
  ) { }

  ngOnInit() {
    this.getHistory();
  }

  getHistory(){
    this.historyS.all()
        .subscribe((response: any[]) => {
          this.history = response
            .filter((history) => history.what === ActionType.ResetServer)
            .map(row=>{
              if( row['user'] ){
                row['user'] = row['user']['name'] + ' [' + row['user']['email'] + ']';
              }
              if( row['when'] ){
                row['when'] = new Date(row['when']);
              }

              if( row['success'] ){
                row['icon'] = this.icons['green'];
              }else{
                row['icon'] = this.icons['red'];
              }

              return row;
            });
        });
  }

  styleIcon(row, that) {
    // colore a celula de acordo com o icon
    for ( const key of Object.keys(that.icons) ) {
      if (that.icons[key] === row.icon) {
        return key;
      }
    }
  }

  confirm() {
    this.dialog.modal('Tem certeza?',
                      { this: this },
                      [ { text: 'Cancelar', close: true },
                        { text: 'Reiniciar', close: true, callback: this.reset }]
    ).subscribe((response) => {

    });
  }

  reset(that) {
    return that.adminS.reset(that.selected).subscribe((response) => {
      that.dialog.snack('Servidor reiniciado', SnackType.Success);
      that.getHistory();
    }, (err) => {
      that.dialog.snack('Erro ao reiniciar servidor', SnackType.Error);
    });
  }

}
