import { OnInit } from '@angular/core';
import { HistoryService, ActionType, ServerName } from 'src/app/services/history.service';
import { ServerService } from 'src/app/services/servers.service';
import { DialogService, SnackType } from 'src/app/services/dialog.service';
var ServersInfoComponent = /** @class */ (function () {
    function ServersInfoComponent(historyS, serverS, dialog) {
        this.historyS = historyS;
        this.serverS = serverS;
        this.dialog = dialog;
        this.columns = [
            { name: 'Servidor', value: 'server' },
            { name: 'Uptime', value: 'uptime' },
            { name: 'Certificado válido até', value: 'ssl' },
            { name: 'Versão', value: 'version' },
            { name: 'Resultado último reset', value: 'icon', icon: 'icon', color: this.styleIcon },
            { name: 'Último reset', value: 'when' },
            { name: 'Por quem', value: 'user' },
        ];
        this.icons = {
            'undefined': 'help',
            'red': 'error',
            'yellow': 'warning',
            'green': 'check_circle'
        };
        this.history = [];
        this.serverList = [
            ServerName.SAAS,
            ServerName.SAAS_QA,
            ServerName.BR,
            ServerName.PNP,
            ServerName.LATAM,
            ServerName.TPX,
            ServerName.MEXICO,
            ServerName.API
        ];
        this.serversInfo = [];
    }
    ServersInfoComponent.prototype.ngOnInit = function () {
        this.getServersInfo();
    };
    ServersInfoComponent.prototype.getServersInfo = function () {
        var _this = this;
        this.historyS.all()
            .subscribe(function (response) {
            var history = response
                // get only server resets
                .filter(function (history) { return history.what === ActionType.ResetServer; })
                // get only some servers
                .filter(function (history) { return _this.serverList.indexOf(history.server) !== -1; })
                // process the rows for displaying
                .map(function (row) {
                if (row['user']) {
                    row['user'] = row['user']['name'] + ' [' + row['user']['email'] + ']';
                }
                if (row['when']) {
                    row['when'] = new Date(row['when']);
                }
                if (row['success']) {
                    row['icon'] = _this.icons['green'];
                }
                else {
                    row['icon'] = _this.icons['red'];
                }
                return row;
            })
                // sort array by when the reset happened
                .sort(function (a, b) {
                return b['when'] - a['when'];
            });
            var _loop_1 = function (i) {
                // if the current index is not the earliest time
                //  a reset on this server happened, drop it
                if (history.findIndex(function (row) { return row['server'] === history[i]['server']; }) !== i) {
                    history.splice(i, 1);
                    i--;
                }
                out_i_1 = i;
            };
            var out_i_1;
            // keep only the last reset of each server
            for (var i = 0; i < history.length; i++) {
                _loop_1(i);
                i = out_i_1;
            }
            // save it to show on table
            _this.history = history;
            // get more information for each server
            _this.history.forEach(function (row) {
                // uptime
                _this.serverS.uptime(row['server']).subscribe(function (uptime) {
                    row['uptime'] = uptime;
                }, function (err) {
                    console.error(err);
                    _this.dialog.snack("Erro ao pegar uptime do servidor " + row['server'], SnackType.Error);
                });
                // version
                _this.serverS.version(row['server']).subscribe(function (version) {
                    row['version'] = version;
                }, function (err) {
                    console.error(err);
                    _this.dialog.snack("Erro ao pegar vers\u00E3o do servidor " + row['server'], SnackType.Error);
                });
                // certificate
                _this.serverS.certificate(row['server']).subscribe(function (certInfo) {
                    row['ssl'] = new Date(certInfo['to']);
                }, function (err) {
                    console.error(err);
                    _this.dialog.snack("Erro ao verificar validade do certificado do servidor " + row['server'], SnackType.Error);
                });
            });
        });
    };
    ServersInfoComponent.prototype.styleIcon = function (row, that) {
        // colore a celula de acordo com o icon
        for (var _i = 0, _a = Object.keys(that.icons); _i < _a.length; _i++) {
            var key = _a[_i];
            if (that.icons[key] === row.icon) {
                return key;
            }
        }
    };
    return ServersInfoComponent;
}());
export { ServersInfoComponent };
