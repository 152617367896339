import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/services/auth.service';
import { DialogService, SnackType } from 'src/app/services/dialog.service';

// enum de roles possíveis
export enum UserRole {
  ServerReset = 'server_reset',
  DemoReset = 'demo_reset',
};

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private dialog: DialogService,
    private router: Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let roles: UserRole[] = next.data.guard.roles as UserRole[];
    let user = this.auth.getUser();

    // todos os roles recebidos devem estar presentes em user.roles
    if( roles.every(role => {
      return user.roles.indexOf(role) !== -1;
    }) ){
      return true;
    }else{
      this.dialog.snack('Este usuário não possui permissões necessárias para acessar esta página', SnackType.Error);
      this.router.navigate(['/']);
      return false;
    }
  }

}
