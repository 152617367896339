// default components:
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// system components:
import { SharedModule } from '../shared.module';

// user-defined components:
import { TableComponent, TableToggleColumnsDialogComponent } from './table/table.component';
import { DialogComponent, SnackbarComponent } from '../services/dialog.service';

@NgModule({
  declarations: [
    TableComponent,
    DialogComponent,
    SnackbarComponent,
    TableToggleColumnsDialogComponent,

  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    TableComponent,
  ],
  entryComponents:[
    DialogComponent,
    SnackbarComponent,
    TableToggleColumnsDialogComponent,

  ]
})
export class ComponentsModule { }
