import { HttpClient } from '@angular/common/http';
import { LoggerService } from './logger.service';
import { SessionService } from './session.service';
import * as i0 from "@angular/core";
import * as i1 from "./logger.service";
import * as i2 from "./session.service";
import * as i3 from "@angular/common/http";
export var ActionType;
(function (ActionType) {
    ActionType["ResetServer"] = "server-reset";
    ActionType["ResetDemoDatabase"] = "demo-database-reset";
})(ActionType || (ActionType = {}));
;
export var ServerName;
(function (ServerName) {
    ServerName["BR"] = "br.aware.blue";
    ServerName["QA"] = "qa.aware.blue";
    ServerName["PNP"] = "pnp.aware.blue";
    ServerName["LATAM"] = "latam.aware.blue";
    ServerName["TPX"] = "tpx.aware.blue";
    ServerName["DEMO"] = "demo.aware.blue";
    ServerName["MX"] = "mx.aware.blue";
    ServerName["MEXICO"] = "mexico.aware.blue";
    ServerName["API"] = "api.br.aware.blue";
    ServerName["SAAS"] = "saas.awarelog.com";
    ServerName["SAAS_QA"] = "qa.saas.awarelog.com";
})(ServerName || (ServerName = {}));
;
var HistoryService = /** @class */ (function () {
    function HistoryService(logger, session, http) {
        this.logger = logger;
        this.session = session;
        this.http = http;
    }
    HistoryService.prototype.all = function () {
        return this.http.get('/history');
    };
    HistoryService.prototype.last = function () {
        return this.http.get('/history/last');
    };
    HistoryService.ngInjectableDef = i0.defineInjectable({ factory: function HistoryService_Factory() { return new HistoryService(i0.inject(i1.LoggerService), i0.inject(i2.SessionService), i0.inject(i3.HttpClient)); }, token: HistoryService, providedIn: "root" });
    return HistoryService;
}());
export { HistoryService };
