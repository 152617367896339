import { Component, OnInit } from '@angular/core';

import { HistoryService, ActionType, ServerName } from 'src/app/services/history.service';
import { ServerService } from 'src/app/services/servers.service';
import { DialogService, SnackType } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-servers-info',
  templateUrl: './servers-info.component.html',
  styleUrls: ['./servers-info.component.scss']
})
export class ServersInfoComponent implements OnInit {
  columns = [
    {name:'Servidor',value:'server'},
    {name:'Uptime',value:'uptime'},
    {name:'Certificado válido até',value:'ssl'},
    {name:'Versão',value:'version'},
    {name:'Resultado último reset',value:'icon',icon:'icon',color:this.styleIcon},
    {name:'Último reset',value:'when'},
    {name:'Por quem',value:'user'},
  ];
  icons = {
    'undefined': 'help',
    'red':       'error',
    'yellow':    'warning',
    'green':     'check_circle'
  };
  history: any[] = [];

  serverList = [
    ServerName.SAAS,
    ServerName.SAAS_QA,
    ServerName.BR,
    ServerName.PNP,
    ServerName.LATAM,
    ServerName.TPX,
    ServerName.MEXICO,
    ServerName.API
  ];
  serversInfo: any[] = [];

  constructor(
    private historyS: HistoryService,
    private serverS: ServerService,
    private dialog: DialogService,
  ) { }

  ngOnInit() {
    this.getServersInfo();
  }

  getServersInfo(){
    this.historyS.all()
        .subscribe((response: any[]) => {
          let history = response
          // get only server resets
            .filter((history) => history.what === ActionType.ResetServer)
          // get only some servers
            .filter((history) => this.serverList.indexOf(history.server) !== -1 )
          // process the rows for displaying
            .map(row=>{
              if( row['user'] ){
                row['user'] = row['user']['name'] + ' [' + row['user']['email'] + ']';
              }
              if( row['when'] ){
                row['when'] = new Date(row['when']);
              }

              if( row['success'] ){
                row['icon'] = this.icons['green'];
              }else{
                row['icon'] = this.icons['red'];
              }

              return row;
            })
          // sort array by when the reset happened
            .sort((a,b) => {
              return b['when'] - a['when'];
            });

          // keep only the last reset of each server
          for(let i=0;i<history.length;i++){
            // if the current index is not the earliest time
            //  a reset on this server happened, drop it
            if(history.findIndex((row)=>row['server']===history[i]['server']) !== i){
              history.splice(i,1);
              i--;
            }
          }

          // save it to show on table
          this.history = history;

          // get more information for each server
          this.history.forEach((row) => {
            // uptime
            this.serverS.uptime(row['server']).subscribe((uptime) => {
              row['uptime'] = uptime;
            },(err) => {
              console.error(err);
              this.dialog.snack(`Erro ao pegar uptime do servidor ${row['server']}`, SnackType.Error);
            });
            // version
            this.serverS.version(row['server']).subscribe((version) => {
              row['version'] = version;
            },(err) => {
              console.error(err);
              this.dialog.snack(`Erro ao pegar versão do servidor ${row['server']}`, SnackType.Error);
            });
            // certificate
            this.serverS.certificate(row['server']).subscribe((certInfo) => {
              row['ssl'] = new Date(certInfo['to']);
            },(err) => {
              console.error(err);
              this.dialog.snack(`Erro ao verificar validade do certificado do servidor ${row['server']}`, SnackType.Error);
            })
          });

        });
  }

  styleIcon(row, that) {
    // colore a celula de acordo com o icon
    for ( const key of Object.keys(that.icons) ) {
      if (that.icons[key] === row.icon) {
        return key;
      }
    }
  }

}
