import { Routes } from '@angular/router';
// guards:
import { AuthGuard } from '../guards/auth.guard';
import { NoAuthGuard } from '../guards/no-auth.guard';
// page components:
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
// module routes:
import { AdminRoutes } from './admin/admin-routing.module';
// routes:
var pagesRoutes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    {
        path: 'login',
        canActivate: [NoAuthGuard],
        component: LoginComponent
    },
    { path: 'logout', component: LogoutComponent },
    {
        // necessário estar logado
        path: '',
        canActivate: [AuthGuard],
        children: [
            { path: 'admin', children: AdminRoutes }
        ],
    },
    { path: '**', component: PageNotFoundComponent }
];
var PagesRoutingModule = /** @class */ (function () {
    function PagesRoutingModule() {
    }
    return PagesRoutingModule;
}());
export { PagesRoutingModule };
