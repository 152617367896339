import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoggerService } from '../../services/logger.service';
import { AuthService } from '../../services/auth.service';
var LogoutComponent = /** @class */ (function () {
    function LogoutComponent(logger, auth, router) {
        this.logger = logger;
        this.auth = auth;
        this.router = router;
    }
    LogoutComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.auth.logout()
            .subscribe(function (response) {
            _this.router.navigate(['/']);
        });
    };
    return LogoutComponent;
}());
export { LogoutComponent };
