<div class="app-login">

  <img id="logo" src="assets/images/logos-name-vertical-transp.png">

  <h4>Olá, autentique-se no sistema abaixo.</h4>

  <mat-card>
    <form #form="ngForm">
      <mat-form-field>
        <input matInput required
          name="email"
          [(ngModel)]="credentials.email"
          placeholder="Email"
        >
      </mat-form-field>
      <mat-form-field>
        <input matInput required
          name="password"
          [(ngModel)]="credentials.password"
          placeholder="Senha"
          type="password"
        >
      </mat-form-field>

      <button mat-raised-button
        type="submit"
        color="green"
        [disabled]="!form.valid"
        (click)="login()"
      >
        ENTRAR NO SISTEMA
      </button>
    </form>
  </mat-card>

  <span fxFlex="25%"></span>

</div>
