import { finalize, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { LoggerService } from '../services/logger.service';

/************************************
 *
 * Quando environment.production === true:
 *   não faz nada com a requisição
 *
 * Quando environment.production === false:
 *   chama LoggerService.log para todas as requisições http
 *
 */
@Injectable()
export class LoggerInterceptor implements HttpInterceptor {

  constructor(private logger: LoggerService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const started = Date.now();
    let ok: string;

    if( environment.production ){
      return next.handle(req);
    } else {
      // extend server response observable with logging
      return next.handle(req)
                 .pipe(
                   tap(
                     // Succeeds when there is a response; ignore other events
                     event => ok = event instanceof HttpResponse ? 'succeeded' : '',
                     // Operation failed; error is an HttpErrorResponse
                     error => ok = 'failed'
                   ),
                   // Log when response observable either completes or errors
                   finalize(() => {
                     const elapsed = Date.now() - started;
                     const msg = `${req.method} "${req.urlWithParams}"
             ${ok} in ${elapsed} ms.`;
                     this.logger.info(msg);
                   })
                 );
    }
  }
}
