import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { LoggerService } from './logger.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private token: string;
  private user: any;
  public isLoggedIn: boolean;
  public redirectUrl: string; // para onde redirecionar após realizar o login

  constructor(
    private logger: LoggerService,
    private session: SessionService,
    private http: HttpClient
  ) {
    this.token = this.session.get('token');
    this.user = this.session.get('user');
    this.isLoggedIn = !!this.token;
  }

  public getAuthorizationToken() {
    return this.token;
  }

  public getUser() {
    return this.user;
  }

  public logout() {
    if( !this.user ){
      this.session.set('token',this.token);
      this.session.set('user',this.user);
      this.isLoggedIn = !!this.token;
      this.logger.warn('Erro fazer logout');
      return of(null);
    }
    return this.http.post('/auth/logout', { })
               .pipe(
                 tap(
                   (data:any) => {
                     this.token = null;
                     this.user = null;
                     this.session.set('token',this.token);
                     this.session.set('user',this.user);
                     this.isLoggedIn = !!this.token;
                   }
                 )
               );
  }

  public login(email: string, password: string) {
    return this.http.post('/auth/login',
                          { email: email, password: password })
               .pipe(
                 tap(
                   (data:any) => {
                     this.token = data.token;
                     this.user = data.user;
                     this.session.set('token',this.token);
                     this.session.set('user',this.user);
                     this.isLoggedIn = !!this.token;
                   }
                 )
               );
  }

}
