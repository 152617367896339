import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { LoggerService } from './logger.service';
import { SessionService } from './session.service';

export enum ActionType {
  ResetServer = 'server-reset',
  ResetDemoDatabase = 'demo-database-reset',
};

export enum ServerName {
  BR      = 'br.aware.blue',
  QA      = 'qa.aware.blue',
  PNP     = 'pnp.aware.blue',
  LATAM   = 'latam.aware.blue',
  TPX     = 'tpx.aware.blue',
  DEMO    = 'demo.aware.blue',
  MX      = 'mx.aware.blue',
  MEXICO  = 'mexico.aware.blue',
  API     = 'api.br.aware.blue',
  SAAS    = 'saas.awarelog.com',
  SAAS_QA = 'qa.saas.awarelog.com',
};

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(
    private logger: LoggerService,
    private session: SessionService,
    private http: HttpClient
  ) { }

  public all() {
    return this.http.get('/history');
  }

  public last() {
    return this.http.get('/history/last');
  }

}
