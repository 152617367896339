import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { LoggerService } from './logger.service';
import { SessionService } from './session.service';
import { ServerName } from './history.service';

@Injectable({
  providedIn: 'root'
})
export class ServerService {
  private serversUrl = { };

  constructor(
    private logger: LoggerService,
    private session: SessionService,
    private http: HttpClient
  ) {
    this.serversUrl[ServerName.BR] = 'https://a.br.aware.blue:1344/health';
    this.serversUrl[ServerName.PNP] = 'https://a.panalpina.awarelog.com:1344/health';
    this.serversUrl[ServerName.LATAM] = 'https://a.latam.aware.blue:1344/health';
    this.serversUrl[ServerName.TPX] = 'https://a.tpx.aware.blue:1341/health';
    this.serversUrl[ServerName.MX] = 'https://a.mx.aware.blue:1344/health';
    this.serversUrl[ServerName.MEXICO] = 'https://a.mexico.aware.blue:1344/health';
    this.serversUrl[ServerName.API] = 'https://api.br.aware.blue:1344/health';
    this.serversUrl[ServerName.SAAS] = 'https://a.saas.awarelog.com:1348/info';
    this.serversUrl[ServerName.SAAS_QA] = 'https://a.qa.saas.awarelog.com/info';
  }

  public uptime(server: ServerName){
    return this.http.get(`${this.serversUrl[server]}/uptime`,
                         { responseType: 'text' });
  }

  public version(server: ServerName){
    return this.http.get(`${this.serversUrl[server]}/version`,
                         { responseType: 'text' });
  }

  public ping(server: ServerName){
    return this.http.get(`${this.serversUrl[server]}/ping`);
  }

  public certificate(server: ServerName){
    return this.http.get(`/admin/certificate/${server}`);
  }

}
