import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserRole } from 'src/app/guards/role.guard';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  user = {
    roles: []
  };
  UserRole = UserRole;

  constructor(
    private router: Router,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.user = this.auth.getUser();
  }

  goToServersInfo() {
    this.router.navigate(['/','admin','servers-info']);
  }

  goToServerReset() {
    this.router.navigate(['/','admin','server-reset']);
  }

  goToDemoReset() {
    this.router.navigate(['/','admin','demo-reset']);
  }

}
