import { AuthService } from '../services/auth.service';
/************************************
 *
 * Pega o bearer token de AuthService.getAuthorizationToken()
 *   e adiciona como header de 'Authorization'
 * Também adiciona header de 'Content-type' para aceitar json
 *
 */
var AuthInterceptor = /** @class */ (function () {
    function AuthInterceptor(auth) {
        this.auth = auth;
    }
    AuthInterceptor.prototype.intercept = function (req, next) {
        // Get the auth token from the service.
        var authToken = this.auth.getAuthorizationToken();
        // Clone the request and set the new header in one step.
        var authReq = req.clone({ setHeaders: {
                Authorization: 'Bearer ' + authToken,
                'Content-type': 'application/json'
            } });
        // send cloned request with header to the next handler.
        return next.handle(authReq);
    };
    return AuthInterceptor;
}());
export { AuthInterceptor };
