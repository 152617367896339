
<h1 mat-dialog-title>{{title}}</h1>

<div mat-dialog-content>
  <!-- body -->
  <ng-container *ngFor="let line of [].concat(body)">
    <p *ngFor="let subline of [].concat(line.split('
'))">{{subline}}</p>
  </ng-container>
  <!-- forms -->
  <form #form="ngForm">
    <ng-container
      *ngFor="let form of forms; let i = index"
      [ngSwitch]="form.type"
    >
      <mat-form-field *ngSwitchCase="'text'" class="w-100">
        <input matInput
          [name]="'input-'+i"
          [placeholder]="form.text"
          [(ngModel)]="formData[form.variable]"
          [required]="form.required"
          [disabled]="form.disabled"
        >
      </mat-form-field>
      <mat-form-field *ngSwitchCase="'textarea'" class="w-100">
        <textarea matInput
          [name]="'input-'+i"
          [placeholder]="form.text"
          [(ngModel)]="formData[form.variable]"
          [required]="form.required"
          [disabled]="form.disabled"
        ></textarea>
      </mat-form-field>
      <mat-form-field *ngSwitchCase="'date'" class="w-100">
        <input matInput
          [name]="'input-'+i"
          [placeholder]="form.text"
          [(ngModel)]="formData[form.variable]"
          [required]="form.required"
          [disabled]="form.disabled"
          [owlDateTimeTrigger]="rangeDatepicker"
          [owlDateTime]="rangeDatepicker"
        >
        <owl-date-time #rangeDatepicker></owl-date-time>
      </mat-form-field>
      <mat-form-field *ngSwitchCase="'daterange'" class="w-100">
        <input matInput
          [name]="'input-'+i"
          [placeholder]="form.text"
          [(ngModel)]="formData[form.variable]"
          [required]="form.required"
          [disabled]="form.disabled"
          [selectMode]="'range'"
          [owlDateTimeTrigger]="rangeDatepicker"
          [owlDateTime]="rangeDatepicker"
        >
        <owl-date-time #rangeDatepicker></owl-date-time>
      </mat-form-field>
    </ng-container>
  </form>
</div>

<!-- buttons -->
<div mat-dialog-actions fxLayoutAlign="space-between" class="w-100">
  <button mat-button
    *ngFor="let button of buttons"
    [disabled]="button.submit && !form.valid"
    (click)="onClick(button)">{{button.text}}
  </button>
</div>
  