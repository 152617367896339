import { environment } from '../../environments/environment';
/************************************
 *
 * Intercepta requisições para urls iniciadas com '/'
 *   e prefixa elas com a url da API do backend
 *
 */
var ApiInterceptor = /** @class */ (function () {
    function ApiInterceptor() {
    }
    ApiInterceptor.prototype.intercept = function (req, next) {
        if (req.url.indexOf('/') === 0) { // url começa com '/'
            // clone request and replace url
            var apiReq = req.clone({
                url: environment.api.url + req.url
            });
            return next.handle(apiReq);
        }
        else {
            // não faz nada
            return next.handle(req);
        }
    };
    return ApiInterceptor;
}());
export { ApiInterceptor };
