import { OnInit } from '@angular/core';
import { HistoryService, ActionType } from 'src/app/services/history.service';
import { AdminService } from 'src/app/services/admin.service';
import { DialogService, SnackType } from 'src/app/services/dialog.service';
var DemoResetComponent = /** @class */ (function () {
    function DemoResetComponent(historyS, adminS, dialog) {
        this.historyS = historyS;
        this.adminS = adminS;
        this.dialog = dialog;
        this.columns = [
            { name: 'Resultado', value: 'icon', icon: 'icon', color: this.styleIcon },
            { name: 'Servidor', value: 'server' },
            { name: 'O que', value: 'what' },
            { name: 'Quando', value: 'when' },
            { name: 'Quem', value: 'user' },
            { name: 'Observações', value: 'obs' },
        ];
        this.history = [];
        this.icons = {
            'undefined': 'help',
            'red': 'error',
            'yellow': 'warning',
            'green': 'check_circle'
        };
    }
    DemoResetComponent.prototype.ngOnInit = function () {
        this.getHistory();
    };
    DemoResetComponent.prototype.getHistory = function () {
        var _this = this;
        this.historyS.all()
            .subscribe(function (response) {
            _this.history = response
                .filter(function (history) { return history.what === ActionType.ResetDemoDatabase; })
                .map(function (row) {
                if (row['user']) {
                    row['user'] = row['user']['name'] + ' [' + row['user']['email'] + ']';
                }
                if (row['when']) {
                    row['when'] = new Date(row['when']);
                }
                if (row['success']) {
                    row['icon'] = _this.icons['green'];
                }
                else {
                    row['icon'] = _this.icons['red'];
                }
                return row;
            });
        });
    };
    DemoResetComponent.prototype.styleIcon = function (row, that) {
        // colore a celula de acordo com o icon
        for (var _i = 0, _a = Object.keys(that.icons); _i < _a.length; _i++) {
            var key = _a[_i];
            if (that.icons[key] === row.icon) {
                return key;
            }
        }
    };
    DemoResetComponent.prototype.confirm = function () {
        this.dialog.modal('Tem certeza?', { this: this }, [{ text: 'Cancelar', close: true },
            { text: 'Resetar', close: true, callback: this.reset }]).subscribe(function (response) {
        });
    };
    DemoResetComponent.prototype.reset = function (that) {
        return that.adminS.resetDemoDatabase().subscribe(function (response) {
            that.dialog.snack('Banco de dados demo resetado', SnackType.Success);
            that.getHistory();
        }, function (err) {
            that.dialog.snack('Erro ao resetar banco de dados demo', SnackType.Error);
        });
    };
    return DemoResetComponent;
}());
export { DemoResetComponent };
