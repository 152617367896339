import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserRole } from 'src/app/guards/role.guard';
import { AuthService } from 'src/app/services/auth.service';
var HomeComponent = /** @class */ (function () {
    function HomeComponent(router, auth) {
        this.router = router;
        this.auth = auth;
        this.user = {
            roles: []
        };
        this.UserRole = UserRole;
    }
    HomeComponent.prototype.ngOnInit = function () {
        this.user = this.auth.getUser();
    };
    HomeComponent.prototype.goToServersInfo = function () {
        this.router.navigate(['/', 'admin', 'servers-info']);
    };
    HomeComponent.prototype.goToServerReset = function () {
        this.router.navigate(['/', 'admin', 'server-reset']);
    };
    HomeComponent.prototype.goToDemoReset = function () {
        this.router.navigate(['/', 'admin', 'demo-reset']);
    };
    return HomeComponent;
}());
export { HomeComponent };
