// default components:
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// other imports:
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { httpInterceptorProviders } from './interceptors/index';
import { CookieService } from 'ngx-cookie-service';

// user defined modules:
import { SharedModule } from './shared.module';
import { ComponentsModule } from './components/components.module';
import { PagesModule } from './pages/pages.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    ComponentsModule,
    PagesModule,
    AppRoutingModule,
  ],
  providers: [
    httpInterceptorProviders,
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'pt' },
    CookieService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
