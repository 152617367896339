import { HttpClient } from '@angular/common/http';
import { LoggerService } from './logger.service';
import { SessionService } from './session.service';
import * as i0 from "@angular/core";
import * as i1 from "./logger.service";
import * as i2 from "./session.service";
import * as i3 from "@angular/common/http";
var AdminService = /** @class */ (function () {
    function AdminService(logger, session, http) {
        this.logger = logger;
        this.session = session;
        this.http = http;
    }
    AdminService.prototype.reset = function (serverName) {
        return this.http.post('/admin/reset/server', { server: serverName });
    };
    AdminService.prototype.resetDemoDatabase = function () {
        return this.http.post('/admin/reset/demo', {});
    };
    AdminService.ngInjectableDef = i0.defineInjectable({ factory: function AdminService_Factory() { return new AdminService(i0.inject(i1.LoggerService), i0.inject(i2.SessionService), i0.inject(i3.HttpClient)); }, token: AdminService, providedIn: "root" });
    return AdminService;
}());
export { AdminService };
