import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { LoggerService } from './logger.service';
import { SessionService } from './session.service';
import * as i0 from "@angular/core";
import * as i1 from "./logger.service";
import * as i2 from "./session.service";
import * as i3 from "@angular/common/http";
var AuthService = /** @class */ (function () {
    function AuthService(logger, session, http) {
        this.logger = logger;
        this.session = session;
        this.http = http;
        this.token = this.session.get('token');
        this.user = this.session.get('user');
        this.isLoggedIn = !!this.token;
    }
    AuthService.prototype.getAuthorizationToken = function () {
        return this.token;
    };
    AuthService.prototype.getUser = function () {
        return this.user;
    };
    AuthService.prototype.logout = function () {
        var _this = this;
        if (!this.user) {
            this.session.set('token', this.token);
            this.session.set('user', this.user);
            this.isLoggedIn = !!this.token;
            this.logger.warn('Erro fazer logout');
            return of(null);
        }
        return this.http.post('/auth/logout', {})
            .pipe(tap(function (data) {
            _this.token = null;
            _this.user = null;
            _this.session.set('token', _this.token);
            _this.session.set('user', _this.user);
            _this.isLoggedIn = !!_this.token;
        }));
    };
    AuthService.prototype.login = function (email, password) {
        var _this = this;
        return this.http.post('/auth/login', { email: email, password: password })
            .pipe(tap(function (data) {
            _this.token = data.token;
            _this.user = data.user;
            _this.session.set('token', _this.token);
            _this.session.set('user', _this.user);
            _this.isLoggedIn = !!_this.token;
        }));
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.LoggerService), i0.inject(i2.SessionService), i0.inject(i3.HttpClient)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
