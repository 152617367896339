// default modules:
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesRoutingModule } from './pages-routing.module';

// shared modules:
import { SharedModule } from '../shared.module';
import { ComponentsModule } from '../components/components.module';

// page components:
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LogoutComponent } from './logout/logout.component';

// page modules:
import { AdminModule } from './admin/admin.module';

@NgModule({
  declarations: [
    LoginComponent,
    PageNotFoundComponent,
    LogoutComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ComponentsModule,
    AdminModule,
    PagesRoutingModule, // deve ficar por último pela página '**'
  ]
})
export class PagesModule { }
