import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
import * as i2 from "@angular/router";
var NoAuthGuard = /** @class */ (function () {
    function NoAuthGuard(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    NoAuthGuard.prototype.canActivate = function (next, state) {
        if (this.authService.isLoggedIn) {
            this.router.navigate(['/admin']);
            return false;
        }
        return true;
    };
    NoAuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function NoAuthGuard_Factory() { return new NoAuthGuard(i0.inject(i1.AuthService), i0.inject(i2.Router)); }, token: NoAuthGuard, providedIn: "root" });
    return NoAuthGuard;
}());
export { NoAuthGuard };
