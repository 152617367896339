import * as i0 from "@angular/core";
/*****************************
 * Mais informação:
 *
 *   https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage
 *   https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
 *
 */
var SessionService = /** @class */ (function () {
    function SessionService() {
    }
    SessionService.prototype.set = function (key, value) {
        sessionStorage.setItem(key, JSON.stringify(value));
    };
    SessionService.prototype.get = function (key) {
        return JSON.parse(sessionStorage.getItem(key));
    };
    SessionService.ngInjectableDef = i0.defineInjectable({ factory: function SessionService_Factory() { return new SessionService(); }, token: SessionService, providedIn: "root" });
    return SessionService;
}());
export { SessionService };
